import { InnerEnterpriseVo } from '@/models/common.model';
import { SchoolModuleVo } from '@/packages/admin/official-website-set/models/common.model';
import { baseUtil } from '@xyd/utils';

// Module path
export const commonModulePath = 'common/';

export interface CommonState {
  themeColor: string; // 当前企业主题色
  enterpriseInfo: InnerEnterpriseVo;
  module: SchoolModuleVo;
}

export const initCommonState: CommonState = {
  themeColor: '#fff',
  enterpriseInfo: null,
  module: null,
};

export const COMMON_STATE_KEYS =
  baseUtil.getSameKeyValueObject(initCommonState);
