import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { mainRoutes } from './routes/main.routes';

Vue.use(VueRouter);
const routes: RouteConfig[] = [...mainRoutes];

export const router = new VueRouter({
  mode: 'hash',
  routes,
});

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return (originalPush.call(this, location) as any).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return (originalReplace.call(this, location) as any).catch((err) => err);
};
