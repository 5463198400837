<template>
  <config-provider :locale="locale" :autoInsertSpaceInButton="false">
    <app />
  </config-provider>
</template>

<script>
import Vue from 'vue';
import {
  ConfigProvider,
  Button,
  DatePicker,
  Input,
  InputNumber,
  Carousel,
  Table,
  Select,
  Row,
  Col,
  Icon,
  Form,
  FormModel,
  Popconfirm,
  message,
  Progress,
  Switch,
  Cascader,
  Modal,
  Tag,
  Pagination,
  Radio,
  Result,
} from 'ant-design-vue';
import App from './App.vue';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import 'ant-design-vue/dist/antd.less';
Vue.prototype.$message = message;
message.config({
  duration: 2, // 提示时常单位为s
  top: '150px', // 距离顶部的位置
  maxCount: 3,
});
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$info = Modal.info;
Vue.use(Button);
Vue.use(DatePicker);
Vue.use(Input);
Vue.use(Carousel);
Vue.use(Table);
Vue.use(Select);
Vue.use(Row);
Vue.use(Col);
Vue.use(Icon);
Vue.use(Form);
Vue.use(FormModel);
Vue.use(Progress);
Vue.use(Switch);
Vue.use(Cascader);
Vue.use(Modal);
Vue.use(Tag);
Vue.use(Pagination);
Vue.use(Radio);
Vue.use(InputNumber);
Vue.use(Result);
Vue.use(Popconfirm);

export default {
  components: {
    App,
    ConfigProvider,
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>

<style lang="less" scope></style>
