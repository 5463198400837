import Vue from 'vue';
import Entry from './Entry.vue';
import { router } from './router';
import store from './store';
import '@/styles/index.less'; // global css
import 'animate.css';
import '@/filters';
import '@/directives/animate.directive';
import { loginUtil } from './utils/login.util';
import VueClipboard from 'vue-clipboard2';
import VueCropper from 'vue-cropper';
Vue.use(VueCropper);
Vue.config.productionTip = false;
loginUtil.initData();
Vue.use(VueClipboard);
new Vue({
  router,
  store,
  render: (h) => h(Entry),
}).$mount('#app');
