import { COMMON_ACTION_KEYS } from './common.action-keys';

const { loadUserInfo } = COMMON_ACTION_KEYS;

export const commonActions = {
  // Load user info
  [loadUserInfo](obj: any, payload: any) {
    return new Promise((resolve, reject) => {
      //
    });
  },
};
