// Node env types
export enum NodeEnvTypes {
  development = 'development',
  test = 'test',
  production = 'production',
}

// App env types
export enum AppEnvTypes {
  local = 'local',
  dev = 'dev',
  test = 'test',
  prod = 'prod',
}

export interface EnvConfig {
  env: string;
  baseUrl: string;
}

export interface AllEnvConfig {
  local: EnvConfig;
  dev: EnvConfig;
  test: EnvConfig;
  prod: EnvConfig;
}
