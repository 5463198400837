export class UsuallyUtil {
  // 移动端判断
  _isMobile() {
    return navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
    );
  }
  _isIOS() {
    return /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);
  }
}
export const usuallyUtil = new UsuallyUtil();
