import { RouteConfig } from 'vue-router';
import { authGuard } from '../auth.guard';
import { adminRoutes } from './admin.routes';
import { displayRoutes } from './official-display.routes';
export const mainRoutes: RouteConfig[] = [
  ...displayRoutes,
  ...adminRoutes,
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '我的云校',
    },
    component: () => import('@/pages/Login.page.vue'),
  },
  {
    path: '/page-404',
    name: 'page-404',
    meta: {
      title: '找不到页面',
    },
    component: () => import('@/pages/Page404.page.vue'),
  },
  {
    path: '*',
    redirect: 'page-404',
  },
];
