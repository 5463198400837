import { RouteConfig } from 'vue-router';

export const displayRoutes: RouteConfig[] = [
  {
    path: '/',
    name: 'official',
    meta: {
      title: '我的云校',
    },
    component: () =>
      import('@/packages/school-official/components/top-header.component.vue'),
    redirect: { name: 'official-index' },
    children: [
      {
        path: 'index',
        name: 'official-index',
        component: () =>
          import('@/packages/school-official/pages/Home.page.vue'),
      },
      {
        path: 'school-introduction',
        name: 'school-introduction',
        component: () =>
          import(
            '@/packages/school-official/pages/school-introduction.page.vue'
          ),
      },
      {
        path: 'teacher-introduction',
        name: 'teacher-introduction',
        component: () =>
          import(
            '@/packages/school-official/pages/teacher-introduction.page.vue'
          ),
      },
      {
        path: 'diy-general',
        name: 'diy-general',
        component: () =>
          import('@/packages/school-official/pages/diy-general.page.vue'),
      },
      {
        path: 'classify-list',
        name: 'classify-list',
        component: () =>
          import('@/packages/school-official/pages/classify-list.page.vue'),
      },
      {
        path: 'artical-detail',
        name: 'artical-detail',
        component: () =>
          import('@/packages/school-official/pages/artical-detail.page.vue'),
      },
      {
        path: 'master-online',
        name: 'master-online',
        component: () =>
          import('@/packages/school-official/pages/master-online.page.vue'),
      },
    ],
  },
];
