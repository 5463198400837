import Vue from 'vue';
import Vuex from 'vuex';
import pathify from './pathify';
import { common } from './common/common.module';
Vue.use(Vuex);

export const store = new Vuex.Store({
  plugins: [pathify.plugin],
  modules: {
    common,
  },
  mutations: {},
});

export default store;
