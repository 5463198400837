import { RouteConfig } from 'vue-router';

export const adminRoutes: RouteConfig[] = [
  {
    path: '/admin-home',
    name: 'admin-home',
    meta: {
      title: '官网后台管理',
      isKeepAlive: true,
    },
    component: () => import('@/pages/AdminHome.page.vue'),
    redirect: { name: 'article-management' },
    children: [
      {
        path: 'article-management',
        name: 'article-management',
        meta: {
          title: '文章管理',
          code: null,
        },
        component: () =>
          import(
            '@/packages/admin/article-management/pages/ArticleManagement.page.vue'
          ),
      },
      {
        path: 'add-article',
        name: 'add-article',
        meta: {
          title: '添加文章',
          code: null,
        },
        component: () =>
          import(
            '@/packages/admin/article-management/pages/AddArticle.page.vue'
          ),
      },
      {
        path: 'edit-article',
        name: 'edit-article',
        meta: {
          title: '编辑文章',
          code: null,
        },
        component: () =>
          import(
            '@/packages/admin/article-management/pages/EditArticle.page.vue'
          ),
      },
      {
        path: '/official-website-set',
        name: 'official-website-set',
        meta: {
          title: '官网设置',
        },
        component: () =>
          import('@/packages/admin/official-website-set/pages/index.page.vue'),
        redirect: { name: 'set-and-base-info' },
        children: [
          {
            path: 'set-and-base-info',
            name: 'set-and-base-info',
            meta: {
              title: '官网设置及基础信息',
              code: null,
            },
            component: () =>
              import(
                '@/packages/admin/official-website-set/pages/set-and-base-info.page.vue'
              ),
          },
          {
            path: 'school-idea-set',
            name: 'school-idea-set',
            meta: {
              title: '办学理念设置',
              code: null,
            },
            component: () =>
              import(
                '@/packages/admin/official-website-set/pages/school-idea-set.page.vue'
              ),
          },
          {
            path: 'swiper-set',
            name: 'swiper-set',
            meta: {
              title: '轮播图设置',
              code: null,
            },
            component: () =>
              import(
                '@/packages/admin/official-website-set/pages/swiper-set.page.vue'
              ),
          },
          {
            path: 'website-footer-set',
            name: 'website-footer-set',
            meta: {
              title: '网站底部信息与友情链接',
              code: null,
            },
            component: () =>
              import(
                '@/packages/admin/official-website-set/pages/website-footer-set.page.vue'
              ),
          },
          {
            path: 'nav-set',
            name: 'nav-set',
            meta: {
              title: '导航设置',
              code: null,
            },
            component: () =>
              import(
                '@/packages/admin/official-website-set/pages/nav-set.page.vue'
              ),
          },
        ],
      },
      {
        path: '/close-school',
        name: 'close-school',
        meta: {
          title: '学校管理',
        },
        component: () =>
          import('@/packages/admin/close-school/pages/index.page.vue'),
      },
      {
        path: '/master-online-manage',
        name: 'master-online-manage',
        meta: {
          title: '校长在线',
        },
        component: () =>
          import('@/packages/admin/master-online/pages/index.page.vue'),
      },
    ],
  },
];
