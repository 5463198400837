export const GLOBAL_API_HEADER_KEYS = {
  token: 'token',
  eId: 'eId',
  campusId: 'campusId',
  periodId: 'periodId',
  roleCode: 'roleCode',
  refId: 'refId',
};

export const INIT_PARAM_KEYS = {
  token: 'token',
  eId: 'eId',
  campusId: 'campusId',
  periodId: 'periodId',
  roleCode: 'roleCode',
  refId: 'refId',
  refName: 'refName',
  to: 'to',
};

export const SESSION_STORAGE_KEYS = INIT_PARAM_KEYS;

export const LOCAL_STORAGE_KEYS = {
  ...INIT_PARAM_KEYS,
  tokenCreateTime: 'tokenCreateTime',
};

export const NO_AUTH_PATH_LIST: string[] = [];

type InitParamsAll = typeof INIT_PARAM_KEYS;
export type InitParams = Partial<InitParamsAll>;

export const TOKEN_EXPIRE_DURATION = 12 * 60; // Minutes
