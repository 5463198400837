import { currentEnvConfig } from '@/configs/env.config';
import { HttpService } from '@xyd/services';
import notification from 'ant-design-vue/es/notification';

export const httpService = new HttpService({
  baseURL: currentEnvConfig.baseUrl,
});

httpService.setInterceptors({
  requestInterceptor: {
    onFulfilled: function (config) {
      return config;
    },
    onRejected: function (error) {
      return Promise.reject(error);
    },
  },
  responseInterceptor: {
    onFulfilled: function (response) {
      if (response.data.success === false) {
        if (response.data.code === 'UN_AUTHORIZED') {
          if (response.data.message === '无效的身份凭证') {
            notification.error({
              message: '温馨提示',
              description: '无效的身份凭证，请重新登录!',
            });
          } else if (response.data.message) {
            notification.error({
              message: '温馨提示',
              description: response.data.message,
            });
          }
        } else {
          if (
            response.status === 500 ||
            response.status === 400 ||
            response.data.code === 'INTERNAL_SERVER_ERROR'
          ) {
            let errCode = '';
            let message = '';
            let title = '';
            if (response.data.message) {
              const index1 = response.data.message.indexOf('[');
              const index2 = response.data.message.indexOf(']');
              if (index1 !== -1 && index2 !== -1) {
                errCode = response.data.message.substring(index1 + 1, index2);
                message = '网络开小差啦';
                title = '系统提示';
              } else {
                message = response.data.message;
                title = '温馨提示';
              }
            }
            notification.warn({
              message: title,
              description: message,
            });
          } else if (response.data.message) {
            notification.warn({
              message: '温馨提示',
              description: response.data.message,
            });
          }
        }
      }

      return response;
    },
    onRejected: function (error) {
      return Promise.reject(error);
    },
  },
});

export const httpClient = httpService.httpClient;
export default httpClient;
