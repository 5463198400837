import {
  GLOBAL_API_HEADER_KEYS,
  InitParams,
  INIT_PARAM_KEYS,
  LOCAL_STORAGE_KEYS,
  NO_AUTH_PATH_LIST,
  SESSION_STORAGE_KEYS,
  TOKEN_EXPIRE_DURATION,
} from '@/configs/common.config';
import { httpService } from '@/services/http.service';
import { localStorageUtil, sessionStorageUtil } from '@xyd/utils';
import { differenceInMinutes } from 'date-fns';
import { platformService } from '@/services/platform.service';
import { router } from '@/router';
import { commonService } from '@/services/common.service';
class LoginUtil {
  initData() {
    this.checkTokenValidity();
    this.parseInitParams();
  }
  checkTokenValidity() {
    const now = new Date();
    const createTimeStr = this.getTokenCreateTime();
    const createTime = createTimeStr && new Date(createTimeStr);

    if (createTime) {
      const diffMinutes = differenceInMinutes(now, createTime);
      if (diffMinutes > TOKEN_EXPIRE_DURATION) {
        // Delete local token
        localStorage.removeItem(LOCAL_STORAGE_KEYS.token);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.tokenCreateTime);
      }
    }

    if (platformService.isEnterpriseWxBrowser()) {
      localStorage.removeItem('token');
    }
  }

  getTokenCreateTime() {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.tokenCreateTime);
  }
  async parseInitParams() {
    const params = new URLSearchParams(window.location.search);
    const otherParams = this.getOtherParamsFromUrl(params);

    const token = params.get(INIT_PARAM_KEYS.token) || '';
    const eId = params.get(INIT_PARAM_KEYS.eId) || '';
    const campusId = params.get(INIT_PARAM_KEYS.campusId) || '';
    const periodId = params.get(INIT_PARAM_KEYS.periodId) || '';
    const roleCode = params.get(INIT_PARAM_KEYS.roleCode) || '';
    const refId = params.get(INIT_PARAM_KEYS.refId) || '';
    const refName = params.get(INIT_PARAM_KEYS.refName) || '';
    const to = params.get(INIT_PARAM_KEYS.to) || '';

    if (NO_AUTH_PATH_LIST.includes(to)) {
      sessionStorageUtil.removeItem(INIT_PARAM_KEYS.token);
      localStorageUtil.removeItem(INIT_PARAM_KEYS.token);
    }

    const tokenFromLocal = this.getLocalToken();
    console.log('🚀 -> LoginUtil -> parseInitParams -> to', to);
    console.log('🚀 -> LoginUtil -> parseInitParams -> token', token);
    console.log(
      '🚀 -> LoginUtil -> parseInitParams -> token from local',
      tokenFromLocal,
    );
    const initParams: InitParams = {
      token,
      eId,
      campusId,
      periodId,
      roleCode,
      refId,
      refName,
    };

    this.saveInitParamsToSession(initParams);
    delete initParams.to; // Only save to sessionStorage
    this.saveInitParamsToLocal(initParams);

    // 有token进入 无token进入
    if (token || tokenFromLocal) {
      this.setApiHeaders();
      this.goToTargetPath(otherParams);
      // await commonService.queryEnterpriseInfo();
    } else {
      // 对 访客 进一步判断
      if (NO_AUTH_PATH_LIST.includes(to)) {
        this.setApiHeaders();
        this.goToNoAuthTargetPath(otherParams);
      } else {
        this.setApiHeaders();
        this.goToTargetPath(otherParams);
      }
    }
  }

  goToNoAuthTargetPath(params: any) {
    const targetPath = this.getTargetPath();
    if (NO_AUTH_PATH_LIST.some((el) => targetPath?.startsWith(el))) {
      this.goToTargetPath(params);
    }
  }

  getTargetPath() {
    return sessionStorageUtil.getItem(SESSION_STORAGE_KEYS.to);
  }

  clearTargePath() {
    return sessionStorageUtil.removeItem(SESSION_STORAGE_KEYS.to);
  }

  goToTargetPath(params: any) {
    const targetPath = this.getTargetPath();

    if (targetPath) {
      router.replace({
        path: targetPath,
        query: params || {},
      });
      this.clearTargePath();
    }

    // setTimeout(() => {
    //   this.clearSearchParams()
    // }, 100)
  }

  getInitParams(): InitParams {
    return {
      token: this.getFromStorage(INIT_PARAM_KEYS.token) || '',
      eId: this.getFromStorage(INIT_PARAM_KEYS.eId) || '',
      campusId: this.getFromStorage(INIT_PARAM_KEYS.campusId) || '',
      periodId: this.getFromStorage(INIT_PARAM_KEYS.periodId) || '',
      roleCode: this.getFromStorage(INIT_PARAM_KEYS.roleCode) || '',
      refId: this.getFromStorage(INIT_PARAM_KEYS.refId) || '',
      to: this.getFromStorage(INIT_PARAM_KEYS.to) || '',
    };
  }

  setApiHeaders() {
    const initParams = this.getInitParams();
    httpService.setHeaders({
      token: initParams.token || '',
      eId: initParams.eId || '',
      // campusId: initParams.campusId || '',
      // periodId: initParams.periodId || '',
      // roleCode: initParams.roleCode || '',
      // refId: initParams.refId || '',
    });
  }

  saveInitParamsToLocal(params: InitParams) {
    Object.keys(params).forEach((key) => {
      const value = params[key];
      if (value) {
        localStorageUtil.setItem(key, value);
      }

      if (key === LOCAL_STORAGE_KEYS.tokenCreateTime) {
        localStorageUtil.setItem(
          LOCAL_STORAGE_KEYS.tokenCreateTime,
          new Date().toISOString(),
        );
      }
    });
  }

  saveInitParamsToSession(params: InitParams) {
    Object.keys(params).forEach((key) => {
      const value = params[key];
      if (value) {
        sessionStorageUtil.setItem(key, value);
      }
    });
  }

  getLocalToken() {
    return this.getFromStorage(SESSION_STORAGE_KEYS.token);
  }

  getRefName() {
    return this.getFromStorage(SESSION_STORAGE_KEYS.refName);
  }

  decodeQuery(query: Record<string, any>) {
    const decodedQuery = {} as any;

    const keys = Object.keys(query) || [];
    keys.forEach((key) => {
      decodedQuery[key] = decodeURIComponent(query[key]);
    });

    return decodedQuery;
  }

  getOtherParamsFromUrl(params: URLSearchParams) {
    const obj = Object.fromEntries(params);

    const result = this.decodeQuery(obj);
    for (const i in result) {
      if (INIT_PARAM_KEYS[i]) {
        delete result[i];
      }
    }

    return result;
  }

  getFromStorage(key: string) {
    const fromSession = sessionStorage.getItem(key);
    const fromLocal = localStorage.getItem(key);
    return fromSession || fromLocal;
  }

  getToken() {
    return this.getFromStorage(GLOBAL_API_HEADER_KEYS.token);
  }

  userCanAccess() {
    const token = this.getToken();
    return !!token;
  }
}
export const loginUtil = new LoginUtil();
