import { format, parseISO } from 'date-fns';

export const dateFilter = (date, formatStr?: string) => {
  if (!date) return '';

  const isStringDate = typeof date === 'string';
  const defaultFormat = 'yyyy-M-d HH:mm';
  const theFormat = formatStr || defaultFormat;
  return format(isStringDate ? parseISO(date) : date, theFormat);
};
