
import { Button } from 'vant';
import { usuallyUtil } from '@/utils/usually.util';
import {
  GlobalPhotoGalleryOptions,
  photoGalleryService,
} from '@/services/photo-gallery.service';
import PhotoGallery from '@/components/PhotoGallery.component.vue';
import Vue from 'vue';
export default Vue.extend({
  name: 'app',
  data: function () {
    return {
      globalPhotoGalleryOptions: {
        isShow: false,
        imageList: [],
        initIndex: 0,
      } as GlobalPhotoGalleryOptions,
      scale: 1,
    };
  },
  created() {
    photoGalleryService.init(this);
  },
  mounted() {
    if (!usuallyUtil._isMobile) {
      document.getElementById('app').style.height = '';
    } else if (usuallyUtil._isIOS()) {
      let w =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      this.scale = w / 1200;
      const app = document.getElementById('app');
      app.style.height = (1 / this.scale) * 100 + '%';
      app.style.transform = `scale(${this.scale})`;
    }
  },
  components: {
    [Button.name]: Button,
    PhotoGallery,
  },
});
