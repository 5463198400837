import { AllEnvConfig, AppEnvTypes, EnvConfig } from '@/models/env.model';

function getCurrentEnvConfig(allEnvConfig: AllEnvConfig, appEnv: AppEnvTypes) {
  switch (appEnv) {
    case AppEnvTypes.local:
      return allEnvConfig.local;

    case AppEnvTypes.dev:
      return allEnvConfig.dev;

    case AppEnvTypes.test:
      return allEnvConfig.test;

    case AppEnvTypes.prod:
      return allEnvConfig.prod;

    default:
      return allEnvConfig.local;
  }

  // return allEnvConfig.prod;
}

// const protocol = window.location.protocol;

// 本机版本
export const localEnvConfig: EnvConfig = {
  env: AppEnvTypes.local,
  baseUrl: `https://pass-dog.husiwei.com`,
};

// 开发版本
export const devEnvConfig: EnvConfig = {
  env: AppEnvTypes.dev,
  baseUrl: `https://pass-dog.husiwei.com`,
};

// 测试版
export const testEnvConfig: EnvConfig = {
  env: AppEnvTypes.test,
  baseUrl: `https://pass-dog.husiwei.com`,
};

// 产品版
export const prodEnvConfig: EnvConfig = {
  env: AppEnvTypes.prod,
  baseUrl: `https://api.xiaoyuandao.com`,
};

const allEnvConfig: AllEnvConfig = {
  local: localEnvConfig,
  dev: devEnvConfig,
  test: testEnvConfig,
  prod: prodEnvConfig,
};

export const domain = {
  local: 'https://cloud-school-dev.husiwei.com',
  dev: 'https://cloud-school-dev.husiwei.com',
  prod: 'https://cloud-school.xydqq.cn',
};

export const appCode = {
  local: 'DUTY-WEEK-T',
  dev: 'DUTY-WEEK-T',
  prod: 'DUTY-WEEK',
};

const nodeEnv = process.env.NODE_ENV;
const appEnv = process.env.VUE_APP_ENV as AppEnvTypes;
export const currentEnv = appEnv;

export const currentEnvConfig = getCurrentEnvConfig(allEnvConfig, currentEnv);

console.log('🚀 -> file: env.config.ts -> nodeEnv', nodeEnv);
console.log('🚀 -> file: env.config.ts -> currentEnv', currentEnv);
console.log('🚀 -> file: env.config.ts -> currentEnvConfig', currentEnvConfig);
