import { make } from 'vuex-pathify';
import { commonActions } from './common.actions';
import { commonGetters } from './common.getters';
import { initCommonState } from './common.state';

const autoMutations = make.mutations(initCommonState);
const autoGetters = make.getters(initCommonState);

export const common = {
  namespaced: true,
  state: initCommonState,
  mutations: { ...autoMutations },
  actions: commonActions,
  getters: { ...autoGetters, ...commonGetters },
};
