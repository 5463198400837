export interface GlobalPhotoGalleryOptions {
  imageList: string[];
  initIndex?: number;
}

export class PhotoGalleryService {
  root: any;
  photoGalleryRef: any;

  init(root: any) {
    this.root = root;
  }

  show(options: GlobalPhotoGalleryOptions) {
    if (!this.root) this.throwNoRootException();

    const globalOptions = this.root
      .globalPhotoGalleryOptions as GlobalPhotoGalleryOptions;
    globalOptions.imageList = options.imageList ?? globalOptions.imageList;
    globalOptions.initIndex = options.initIndex ?? globalOptions.initIndex;

    setTimeout(() => {
      this.photoGalleryRef = this.root.$refs['photoGalleryRef'];
      this.photoGalleryRef.show(globalOptions.initIndex);
    }, 20);
  }

  close() {
    if (!this.root) this.throwNoRootException();
  }

  throwNoRootException() {
    throw 'No root, please call init first!';
  }
}

export const photoGalleryService = new PhotoGalleryService();
