import Vue from 'vue';

Vue.directive('class', {
  inserted: function (el, binding: any) {
    // 指令绑定元素
    binding.addClass = () => {
      const { top } = el.getBoundingClientRect();
      const h =
        document.documentElement.clientHeight || document.body.clientHeight;
      if (top < h - 150) {
        //减去200刚好，否则刚露头就执行
        binding.value.split(' ').forEach((clz) => el.classList.add(clz));
        el.classList.remove('app-opacity-0');
        if (binding.addClass) {
          window.removeEventListener('scroll', binding.addClass);
        }
      }
    };

    window.addEventListener('scroll', binding.addClass, true);

    binding.addClass();
  },
  unbind: function (el, binding: any) {
    if (binding.addClass) {
      window.removeEventListener('scroll', binding.addClass);
    }
  },
});
