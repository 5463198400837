
import Vue from 'vue';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';
export default Vue.extend({
  name: 'photo-gallery',
  props: {
    imageList: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    return {
      gallery: null as Viewer,
    };
  },

  methods: {
    show(index?: number) {
      this.gallery = new Viewer(
        this.$refs['photoGalleryComponentRef'] as HTMLElement,
        {
          initialViewIndex: index || 0,
          hide: () => {
            this.gallery.destroy();
          },
        },
      );

      this.gallery.show(true);
    },
  },
});
