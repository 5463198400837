import { COMMON_GETTER_KEYS } from './common.getter-keys';
import { CommonState } from './common.state';

const { isBinded } = COMMON_GETTER_KEYS;

export const commonGetters = {
  [isBinded](state: CommonState, getters: any) {
    return null;
  },
};
